import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MessagePromptProps } from './message-prompt.model';
import { capitalize } from '@lib/capitalize';

@Component({
  selector: 'app-message-prompt',
  templateUrl: './message-prompt.component.html',
  styleUrls: ['./message-prompt.component.scss'],
})
export class MessagePromptComponent implements OnInit {
  private modalBackground!: HTMLDivElement;
  private modalBody!: HTMLDivElement;

  isLoading = false;

  @Input()
  get messagePromptProps(): MessagePromptProps {
    return this._messagePromptProps;
  }
  set messagePromptProps(value: MessagePromptProps) {
    this._messagePromptProps = { ...this.messagePromptProps, ...(value || {}) };
    this.show();
  }
  private _messagePromptProps: MessagePromptProps = {
    title: '',
    widthClass: 'w-96',
    modalHeirarchy: 1,
    showLoaderOnOK: false,
    showOK: true,
  };

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.modalBackground = this.el.nativeElement.firstChild as HTMLDivElement;
    this.modalBody = this.modalBackground.querySelector(
      '#body'
    ) as HTMLDivElement;
  }

  get title() {
    return (
      this.messagePromptProps.title ??
      capitalize(this.messagePromptProps.type ?? 'Message')
    );
  }

  get modalZIndex() {
    if (this.messagePromptProps.modalHeirarchy === 2) {
      return 'z-50';
    }
    return 'z-40';
  }

  close = () => {
    this.modalBody.classList.replace('scale-100', 'scale-0');
    setTimeout(() => {
      this.modalBackground.classList.replace('opacity-100', 'opacity-0');
    }, 300);
    setTimeout(() => {
      this.el.nativeElement.remove();
    }, 600);
  };

  show() {
    this.modalBackground.classList.replace('opacity-0', 'opacity-100');
    setTimeout(() => {
      this.modalBody.classList.replace('scale-0', 'scale-100');
    }, 300);
  }

  onOkClick() {
    this.messagePromptProps.onOk?.(this.close);

    if (!this.messagePromptProps.showLoaderOnOK) {
      this.close();
    } else {
      this.isLoading = true;
    }
  }
}
