import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import * as routerActions from '@store/actions/router.actions';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) {}

  navigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerActions.GO),
        map((action: routerActions.Go) => action.payload),
        tap(({ path, queryParams, extras }) => {
          const returnUrl = sessionStorage.getItem('returnUrl');
          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
          } else {
            this.router.navigate(path, { queryParams, ...extras });
          }
        })
      );
    },
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerActions.BACK),
        tap(() => this.location.back())
      );
    },
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerActions.FORWARD),
        tap(() => this.location.forward())
      );
    },
    { dispatch: false }
  );
}
