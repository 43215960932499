import { createAction, props } from '@ngrx/store';
import { LoginForm, User } from '../../modules/auth/models/auth.model';
import { User as UserAccount } from '@app/modules/shared/models/user.model';

/**
 *
 * Log user in */
export const login = createAction(
  '[Auth] Login',
  props<{ payload: LoginForm }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);

/**
 *
 * Auto login */
export const autoLogin = createAction('[Auth] Auto Login');

/**
 *
 * Set user object */
export const setUser = createAction(
  '[Auth] Set User',
  props<{ payload: User | null }>()
);

/**
 *
 * Log user out */
export const logout = createAction('[Auth] Logout');

/**
 *
 * Account Data */
export const accountData = createAction('[Auth] Account Data');

export const accountDataSuccess = createAction(
  '[Auth] Account Data Success',
  props<{ payload: UserAccount | null }>()
);
