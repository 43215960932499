<div
  [id]="id"
  class="bg-slate-950/50 fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center transition-opacity opacity-0 z-50">
  <div
    id="body"
    class="bg-white rounded-xl overflow-clip transition-transform scale-0"
    [ngClass]="{
      'w-[48rem]': !modal.size || modal.size === 'md',
      'w-[52rem]': modal.size === 'lg',
      'w-[64rem]': modal.size === 'xl'
    }">
    <div class="pt-10 px-12 pb-7 flex items-center">
      <h1 class="font-league font-medium text-ts-purple-1">{{ modal.title }}</h1>
      <button
        (click)="close()"
        class="ml-auto py-2 text-ts-grey-1 hover:text-ts-body cursor-pointer transition-colors">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="max-h-[80vh] overflow-auto">
      <ng-template modalBody></ng-template>
    </div>
    <!-- <div class="bg-slate-100 w-full px-8 py-6 flex justify-end space-x-3">
      <ng-template #defaultFooterTemplate>
        <button (click)="close()"
          class="text-slate-800 border border-slate-300 shadow font-semibold rounded-md py-1.5 block px-3 cursor-pointer select-none transition-shadow hover:shadow-lg">
          Cancel
        </button>
        <button (click)="close()"
          class="bg-indigo-600 text-white shadow font-semibold rounded-md py-1.5 block px-3 cursor-pointer select-none transition-shadow hover:shadow-lg">
          Okay
        </button>
      </ng-template>
      <ng-template [ngTemplateOutlet]="footerTemplate || defaultFooterTemplate"></ng-template>
    </div> -->
  </div>
</div>
