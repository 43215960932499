import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { OptionsProps } from './options.model';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent implements OnInit {
  private optionsBackground!: HTMLDivElement;
  private optionsBody!: HTMLDivElement;

  @Input()
  get optionsProps(): OptionsProps {
    return this._optionsProps!;
  }
  set optionsProps(value: OptionsProps) {
    this._optionsProps = value;
    this.show();
  }
  private _optionsProps?: OptionsProps;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.optionsBackground = this.el.nativeElement.firstChild as HTMLDivElement;
    this.optionsBody = this.optionsBackground.querySelector(
      '#body'
    ) as HTMLDivElement;
  }

  close() {
    this.optionsBody.classList.replace('scale-y-100', 'scale-y-0');
    this.optionsBackground.classList.replace('opacity-100', 'opacity-0');
    setTimeout(() => {
      this.el.nativeElement.remove();
    }, 100);
  }

  avoidClose(event: Event) {
    event.stopPropagation();
  }

  show() {
    this.optionsBody.style.top = `${this.optionsProps.triggerEvent!.clientY}px`;
    this.optionsBody.style.left = `${
      this.optionsProps.triggerEvent!.clientX
    }px`;
    this.optionsBackground.classList.replace('opacity-0', 'opacity-100');
    this.optionsBody.classList.replace('scale-y-0', 'scale-y-100');
  }
}
