<ng-container *ngIf="{ isAuthenticated: isAuthenticated$ | async } as obs">
  <ng-container *ngIf="obs.isAuthenticated; else unauthorized">
    <router-outlet></router-outlet>
  </ng-container>

  <ng-template #unauthorized>
    <router-outlet></router-outlet>
  </ng-template>
</ng-container>

<app-notification aria-live="polite" aria-atomic="true"></app-notification>
