import { Injectable } from '@angular/core';

export interface Notification {
  message: string;
  type: 'neutral' | 'success' | 'error';
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: Notification[] = [];

  notify(message: string) {
    this.notifications.push({
      message: message,
      type: 'neutral',
    });
    this.selfDestruct(message);
  }

  notifySuccess(message: string) {
    this.notifications.push({
      message: message,
      type: 'success',
    });
    this.selfDestruct(message);
  }

  notifyError(message: string) {
    this.notifications.push({
      message: message,
      type: 'error',
    });
    this.selfDestruct(message);
  }

  selfDestruct(message: string) {
    setTimeout(() => {
      const toast = this.notifications.find((it) => it.message === message);
      if (toast) this.removeNotification(toast);
    }, 4500);
  }

  removeNotification(entity: Notification) {
    this.notifications = this.notifications.filter(
      (notification) => notification !== entity
    );
  }
}
