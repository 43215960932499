import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import {
  LoginForm,
  Password,
  UserProfile,
  ValidateCampaignResponse,
} from '@auth/models/auth.model';
import { Response } from '../../shared/models/response.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store';
import { User } from '@app/modules/shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private rootStore: Store<fromRoot.rootState>
  ) {}

  login(payload: LoginForm): Observable<{ username: string }> {
    return this.http.post<{ username: string }>(
      `${environment.idpUrl}/login`,
      payload
    );
  }

  /** Request to get a link to reset password */
  getPasswordResetLink(payload: { username: string }): Observable<any> {
    return this.http.post(`${environment.baseUrl}/account/reset`, payload).pipe(
      tap(() => {
        alert('Link sent successfully');
        this.rootStore.dispatch(fromRoot.logout());
      }),
      catchError((error) => throwError(() => error))
    );
  }

  /** Request to set password */
  setPassword(payload: Password): Observable<UserProfile> {
    return this.http
      .post<Response<UserProfile>>(
        `${environment.baseUrl}/account/signup`,
        payload
      )
      .pipe(
        map((response) => {
          const { data } = response;

          return data;
        }),
        tap(() => {
          alert('User account was activated successfully.');
          this.rootStore.dispatch(fromRoot.logout());
        }),
        catchError((error) => throwError(() => error))
      );
  }

  /** Request to reset password */
  resetPassword(payload: Password): Observable<{ username: string }> {
    return this.http
      .post<{ username: string }>(
        `${environment.idpUrl}/reset/password`,
        payload
      )
      .pipe(
        map((response) => {
          return response;
        }),
        tap(() => {
          alert('Password reset successfully');
          this.rootStore.dispatch(fromRoot.logout());
        }),
        catchError((error) => throwError(() => error))
      );
  }

  /** Account data */
  getCurrentUserAccountData() {
    return this.http.get<Response<User>>(`${environment.baseUrl}/account`).pipe(
      map((response) => {
        const { data } = response;

        return data;
      }),
      catchError((error) => throwError(() => error))
    );
  }

  validateCampaign(campaignId: number, companyId: number) {
    return this.http
      .post<Response<ValidateCampaignResponse>>(
        `${environment.baseUrl}/account/company-campaign/verify`,
        {
          campaign_id: campaignId,
          company_id: companyId,
        }
      )
      .pipe(
        map((response) => {
          const { data } = response;

          return data;
        }),
        catchError((error) => throwError(() => error))
      );
  }
}
