import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import jwt_decode from 'jwt-decode';
import { map, switchMap, catchError, of, concatMap, from, tap } from 'rxjs';

import { AuthService } from '@auth/services/auth.service';
import { CookieService } from '@shared/services/cookie.service';
import { environment } from '@environments/environment';

import * as fromActions from '@store/actions/auth.actions';
import * as fromRoot from '@app/store';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private cookieService: CookieService
  ) {}

  private getDecodedJwtToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.login),
      map((action) => action.payload),
      switchMap((payload) => {
        return this.authService.login(payload).pipe(
          switchMap(() => {
            const filters = JSON.parse(localStorage.getItem('filters') || '{}');
            let { campaign_id, company_id } = filters;

            if (!campaign_id || !company_id) {
              // clear filters
              return of({ company_verified: false, campaign_verified: false });
            }

            campaign_id = Number(campaign_id);
            company_id = Number(company_id);

            return this.authService.validateCampaign(campaign_id, company_id);
          }),
          tap(({ company_verified, campaign_verified }) => {
            const returnUrl = sessionStorage.getItem('returnUrl');
            const returnUrlParams = new URL(window.location.origin + returnUrl)
              .searchParams;

            if (
              (returnUrlParams.get('company_id') ||
                returnUrlParams.get('campaign_id')) &&
              (!company_verified || !campaign_verified)
            ) {
              localStorage.removeItem('filters');
              localStorage.removeItem('allFilters');
              sessionStorage.removeItem('returnUrl');
            }
          }),
          concatMap(() => {
            const bearer = this.cookieService.getCookie('bearer');
            const jwtDecoded = this.getDecodedJwtToken(bearer);
            return from([
              fromActions.setUser({ payload: jwtDecoded }),
              fromActions.accountData(),
              new fromRoot.Go({
                path: [environment.defaultRoute],
              }),
            ]);
          }),
          catchError((error) => {
            return of(
              fromActions.loginFailure({
                error: error,
              })
            );
          })
        );
      })
    );
  });

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.autoLogin),
      concatMap(() => {
        const bearer = this.cookieService.getCookie('bearer');
        const jwtDecoded = this.getDecodedJwtToken(bearer);

        let accountData = localStorage.getItem('accountData') || '{}';
        accountData = JSON.parse(accountData) || null;

        return [
          fromActions.setUser({
            payload: jwtDecoded ? jwtDecoded : null,
          }),
          fromActions.accountDataSuccess({ payload: accountData as any }),
        ];
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.logout),
      concatMap(() => {
        sessionStorage.clear();
        localStorage.removeItem('accountData');
        document.cookie =
          'bearer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=' +
          environment.domain;

        return from([
          fromRoot.resetState(),
          new fromRoot.Go({ path: ['/auth'] }),
        ]);
      })
    );
  });

  accountData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.accountData),
      switchMap(() => {
        return this.authService.getCurrentUserAccountData().pipe(
          map((data) => {
            localStorage.setItem('accountData', JSON.stringify(data));

            return fromActions.accountDataSuccess({ payload: data });
          })
        );
      })
    );
  });
}
