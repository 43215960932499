import { Router, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromRoot from '@app/store';
import { environment } from '@environments/environment';

export const LoginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const rootStore = inject(Store<fromRoot.rootState>);

  return rootStore.select(fromRoot.getIsAuthenticated).pipe(
    take(1),
    map((isAuthenticated) => {
      if (!isAuthenticated) {
        return true;
      }

      return router.createUrlTree([environment.defaultRoute]);
    })
  );
};
