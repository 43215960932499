import { createSelector } from '@ngrx/store';

import * as fromFeature from '@store/reducers';
import * as fromAuth from '@store/reducers/auth.reducer';

const getFeatureState = createSelector(
  fromFeature.getAuthState,
  (state: fromAuth.State) => state
);

export const getUser = createSelector(
  getFeatureState,
  (state: fromAuth.State) => state.user
);

export const getUsersName = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return state.user?.first_name && state.user?.last_name
      ? state.user?.first_name + ' ' + state.user?.last_name
      : '';
  }
);

export const getIsAuthenticated = createSelector(
  getFeatureState,
  (state: fromAuth.State) => !!state.user
);

export const getLoginError = createSelector(
  getFeatureState,
  (state: fromAuth.State) => state.error
);

export const isSuperAdmin = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return state.user?.roles.includes('super_admin');
  }
);

export const isUserAdmin = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return (
      state.userAccount?.is_user_admin ||
      state.userAccount?.is_job_role_admin ||
      state.userAccount?.is_campaign_admin
    );
  }
);

export const userAccountId = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return state.userAccount?.id || null;
  }
);

export const hasUserAdminAccess = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return (
      state.userAccount?.is_user_admin ||
      state.user?.roles.includes('super_admin')
    );
  }
);

export const hasJobRoleAdminAccess = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return (
      state.userAccount?.is_job_role_admin ||
      state.user?.roles.includes('super_admin')
    );
  }
);

export const hasCampaignAdminAccess = createSelector(
  getFeatureState,
  (state: fromAuth.State) => {
    return (
      state.userAccount?.is_campaign_admin ||
      state.user?.roles.includes('super_admin')
    );
  }
);
