import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import * as fromRouterReducer from './router.reducer';
import * as fromAuth from './auth.reducer';

export interface rootState {
  [fromRouterReducer.routerFeatureKey]: fromRouter.RouterReducerState<fromRouterReducer.RouterStateUrl>;
  [fromAuth.authFeatureKey]: fromAuth.State;
}

export const reducers: ActionReducerMap<rootState> = {
  [fromRouterReducer.routerFeatureKey]: fromRouter.routerReducer,
  [fromAuth.authFeatureKey]: fromAuth.reducer,
};

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<fromRouterReducer.RouterStateUrl>
>(fromRouterReducer.routerFeatureKey);

export const getAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);
