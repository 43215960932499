import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as fromRoot from '@app/store';
import { Store } from '@ngrx/store';
import { NotificationService } from '../services/notification.service';
import { environment } from '@environments/environment';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(
    private rootStore: Store<fromRoot.rootState>,
    private notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(environment.onetUrl) ||
      request.url.includes('interview/room') ||
      (request.method === 'POST' &&
        request.url.split('/').pop() === 'job-role') ||
      (request.method === 'POST' &&
        request.url.split('/').slice(-2).join('/') === 'survey/participant')
    ) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        let errorMessage = 'An unknown error occured';

        if (error instanceof HttpErrorResponse) {
          const message = <string>error.error.message || '';
          switch (error.status) {
            case 401:
              if (message.toLocaleLowerCase().includes('bearer')) {
                errorMessage = 'Your session has timed out';
              } else {
                errorMessage = message;
              }
              this.rootStore.dispatch(fromRoot.logout());
              break;

            default:
              if (message) errorMessage = message;
          }
        }

        this.notificationService.notifyError(errorMessage);

        return throwError(() => errorMessage);
      })
    );
  }
}
