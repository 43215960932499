<div
  [class]="
    'bg-slate-950/50 fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center transition-opacity opacity-0 ' +
    modalZIndex
  ">
  <div
    id="body"
    class="bg-white rounded overflow-clip transition-transform scale-0"
    [ngClass]="messagePromptProps.widthClass">
    <div class="px-4 py-4 flex items-center bg-gray-100">
      <h3 class="text-slate-800">{{ messagePromptProps.title }}</h3>
      <button
        (click)="close()"
        class="ml-auto px-2 py-2 text-slate-500 hover:text-slate-800 cursor-pointer transition-colors">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="flex flex-col px-4 py-8 max-h-[60vh] gap-8">
      <div>{{ messagePromptProps.message }}</div>
      <div *ngIf="messagePromptProps.additonalMessage" class="text-ts-grey-2">
        {{ messagePromptProps.additonalMessage }}
      </div>
    </div>

    <div class="w-full px-8 py-6 flex justify-center space-x-3">
      <button
        [disabled]="isLoading"
        (click)="messagePromptProps.onCancel?.(); close();"
        class="secondary">
        {{ messagePromptProps.cancelText ?? 'Cancel' }}
      </button>
      <button
        *ngIf="messagePromptProps.showOK"
        [disabled]="isLoading"
        (click)="onOkClick()"
        class="primary">
        {{ messagePromptProps.okText ?? 'Ok' }}
      </button>
    </div>
  </div>
</div>
