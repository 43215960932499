import { createReducer, on } from '@ngrx/store';
import { User } from '@auth/models/auth.model';
import * as AuthActions from '@store/actions/auth.actions';
import { User as UserAccount } from '@app/modules/shared/models/user.model';

export const authFeatureKey = 'auth';

export interface State {
  user: null | User;
  error: null | string;
  loaded: boolean;
  userAccount: null | UserAccount;
}

export const initialState: State = {
  user: null,
  error: null,
  loaded: false,
  userAccount: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.setUser, (state, action) => {
    return { ...state, user: action.payload };
  }),
  /** Error handling */
  on(AuthActions.loginFailure, (state, action) => {
    return { ...state, error: action.error };
  }),
  on(AuthActions.accountDataSuccess, (state, action) => {
    return { ...state, userAccount: action.payload };
  })
);
