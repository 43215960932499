import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  Renderer2,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() appTooltip = ''; // Tooltip content

  private tooltipElement!: HTMLDivElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    if (!this.tooltipElement) {
      this.createTooltip();
    }

    const hostElementRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipRect = this.tooltipElement.getBoundingClientRect();

    const top = hostElementRect.top - (tooltipRect.height + 8);
    const left =
      hostElementRect.left + hostElementRect.width / 2 - tooltipRect.width / 2;

    this.renderer.setStyle(this.tooltipElement, 'left', left + 'px');
    this.renderer.setStyle(this.tooltipElement, 'top', top + 'px');
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
    this.renderer.setStyle(this.tooltipElement, 'transition', 'opacity 1s');
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
    }
  }

  private createTooltip() {
    this.tooltipElement = this.renderer.createElement('div');
    const text = this.renderer.createText(this.appTooltip);
    this.renderer.appendChild(this.tooltipElement, text);

    this.renderer.addClass(this.tooltipElement, 'tooltip-text');

    this.renderer.appendChild(document.body, this.tooltipElement);
  }

  ngOnDestroy() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
    }
  }
}
