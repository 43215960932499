<div class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <ng-container *ngFor="let toast of notificationService.notifications">
      <div
        class="pointer-events-auto min-w-[30%] w-fit overflow-hidden border rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        [ngClass]="
          toast.type === 'neutral'
            ? 'notification-neutral'
            : this.toast.type === 'success'
            ? 'notification-success'
            : 'notification-error'
        ">
        <div class="p-3">
          <div class="flex justify-between">
            <div class="flex flex-row items-center gap-2 pt-0.5">
              <img
                class="w-6"
                [src]="
                  toast.type === 'neutral'
                    ? '/assets/svgs/notification-neutral.svg'
                    : toast.type === 'success'
                    ? '/assets/svgs/notification-success.svg'
                    : '/assets/svgs/notification-error.svg'
                "
                [alt]="toast.type + 'icon'" />
              <p class="text-base font-medium">{{ toast.message }}</p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button
                (click)="notificationService.removeNotification(toast)"
                type="button"
                class="flex items-center rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
