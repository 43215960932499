<div
  (click)="close()"
  class="bg-slate-950/5 fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center transition-opacity opacity-0 z-50">
  <div
    (click)="avoidClose($event)"
    id="body"
    class="fixed bg-white rounded w-52 transition-all scale-y-0 border border-ts-grey-2 overflow-visible">
    <ul *ngIf="optionsProps as options">
      <li
        class="flex justify-center items-center text-ts-body pl-4 pr-4 border-b hover:bg-ts-grey-4 transition-colors"
        *ngFor="let option of options.options">
        <button
          class="font-overpass font-light flex justify-between items-center w-full h-full text-start py-2"
          (click)="options.onSelect?.(option); option.onClick?.(); close()">
          <span>{{ option.name }}</span>
          <img *ngIf="option.icon" [src]="option.icon" />
        </button>

        <button
          class="flex w-6 h-6 items-center justify-center rounded-full bg-grey-3"
          *ngIf="option?.link"
          (click)="option.link?.onClick?.()">
          <div [appTooltip]="'Copy link to interview'">
            <img [src]="option.link?.icon" alt="icon" />
          </div>
        </button>
      </li>
    </ul>
  </div>
</div>
