import * as fromRouter from '@ngrx/router-store';

import {
  Params,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

export const routerFeatureKey = 'routerReducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    /** Traverse the state tree */
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }

    const { params } = state;

    // Save returnUrl to redirect users to the initial page they were visiting
    if (url && !url.includes('auth')) {
      sessionStorage.setItem('returnUrl', url);
    }

    return { url, queryParams, params };
  }
}
