import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as fromRoot from '@app/store';
import { Store } from '@ngrx/store';
import { createCustomElement } from '@angular/elements';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { OptionsComponent } from './modules/shared/components/options/options.component';
import { MessagePromptComponent } from './modules/shared/components/message-prompt/message-prompt.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isAuthenticated$: Observable<boolean> = this.rootStore.select(
    fromRoot.getIsAuthenticated
  );

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private rootStore: Store<fromRoot.rootState>,
    injector: Injector
  ) {
    const ModalElement = createCustomElement(ModalComponent, { injector });
    customElements.define('modal-element', ModalElement);
    const OptionsElement = createCustomElement(OptionsComponent, { injector });
    customElements.define('app-options', OptionsElement);
    const MessagePromptElement = createCustomElement(MessagePromptComponent, {
      injector,
    });
    customElements.define('app-message-prompt', MessagePromptElement);
  }

  ngOnInit() {
    this.rootStore.dispatch(fromRoot.autoLogin());
    this.route.queryParams.subscribe((params) => {
      const { company_id, campaign_id } = params;
      if (company_id && campaign_id) {
        localStorage.setItem(
          'filters',
          JSON.stringify({ company_id, campaign_id })
        );
        localStorage.setItem('allFilters', JSON.stringify(params));
      }
    });
  }
}
