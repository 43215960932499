import {
  Component,
  // ContentChild,
  ElementRef,
  Input,
  OnInit,
  // TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalBodyDirective } from './modal-body.directive';
import { ModalType } from './modal.model';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
// import { ModalFooterDirective } from './modal-footer.directive';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  private static stackSize = 0;
  private modalBackground!: HTMLDivElement;
  private modalBody!: HTMLDivElement;

  private routerSubscription!: Subscription;

  @Input()
  get modal(): ModalType {
    return this._modal;
  }
  set modal(value: ModalType) {
    this._modal = value;
    this.show();
  }
  private _modal: ModalType = { title: '', size: 'md' };

  @ViewChild(ModalBodyDirective, { static: true })
  modalHost!: ModalBodyDirective;

  // @ContentChild(ModalFooterDirective, { read: TemplateRef })
  // footerTemplate!: TemplateRef<any>;

  constructor(private el: ElementRef, private router: Router) {}

  ngOnInit(): void {
    ModalComponent.stackSize++;
    this.modalBackground = this.el.nativeElement.firstChild as HTMLDivElement;
    this.modalBody = this.modalBackground.querySelector(
      '#body'
    ) as HTMLDivElement;

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.close();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  close() {
    ModalComponent.stackSize--;
    this.modalBody.classList.replace('scale-100', 'scale-0');
    setTimeout(() => {
      this.modalBackground.classList.replace('opacity-100', 'opacity-0');
    }, 300);
    setTimeout(() => {
      this.el.nativeElement.remove();
    }, 600);
  }

  show() {
    const modalBodyViewContainerRef = this.modalHost.viewContainerRef;
    modalBodyViewContainerRef.clear();
    const modalCoponentRef = modalBodyViewContainerRef.createComponent(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._modal.body!
    );
    modalCoponentRef.instance.bodyProps = this._modal.bodyProps;
    modalCoponentRef.instance.modalInstance = {
      close: this.close.bind(this),
    };
    this.modalBackground.classList.replace('opacity-0', 'opacity-100');
    setTimeout(() => {
      this.modalBody.classList.replace('scale-0', 'scale-100');
    }, 300);

    return modalCoponentRef;
  }

  get id() {
    return `modal-${ModalComponent.stackSize}`;
  }

  get zIndex() {
    return 1000 + ModalComponent.stackSize;
  }
}
