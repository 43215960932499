import { createAction } from '@ngrx/store';

export const resetState = createAction('[Root] Reset State');

export function clearState(reducer: any) {
  return (state: any, action: any) => {
    if (action.type === resetState.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
